import React from 'react';
import Select, { components, InputActionMeta } from 'react-select';
import AsyncSelect from "react-select/async";

class SelectSearchCategory extends React.Component {
    constructor(props) {
        super(props);
        this.handleAttorneyName = this.handleAttorneyName.bind(this);
    }

    handleAttorneyName(event) {
        let i;
        var ids = [];
        for(i=0; i < event.length; i++){
            ids.push(event[i].value);
        }
        loadAttorneyListFromHomepage(ids);
    }


    render() {
        const promiseOptions = inputValue => {
            const url = `${this.props.apiUrl}${inputValue ? '?q=' + inputValue : ''}`;
            return fetch(url)
                .then(res => res.json()) //res.json()
                .then(json => json.categories)
                .catch(err => {
                    console.error(err);
                });
        };

        const noOptionsMessage = (obj) => {
            if (obj.inputValue.trim().length === 0) {
                return null
            }
            return 'No matching specialization found';
        }

        const DropdownIndicator = props => {
            return (
                components.DropdownIndicator && (
                    <components.DropdownIndicator {...props}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-7 h-7 text-gray-500 dark" viewBox="0 0 24 24" stroke-width="1.5" stroke="#371d10" fill="#964B00">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M13 10l7.383 7.418c.823 .82 .823 2.148 0 2.967a2.11 2.11 0 0 1 -2.976 0l-7.407 -7.385"></path>
                            <path d="M6 9l4 4"></path>
                            <path d="M13 10l-4 -4"></path>
                            <path d="M3 21h7"></path>
                            <path d="M6.793 15.793l-3.586 -3.586a1 1 0 0 1 0 -1.414l2.293 -2.293l.5 .5l3 -3l-.5 -.5l2.293 -2.293a1 1 0 0 1 1.414 0l3.586 3.586a1 1 0 0 1 0 1.414l-2.293 2.293l-.5 -.5l-3 3l.5 .5l-2.293 2.293a1 1 0 0 1 -1.414 0z"></path>
                        </svg>

                    </components.DropdownIndicator>
                )
            );
        };


        const styles = {
            control: (base, state) => ({
                ...base,
                boxShadow: 0,
                paddingLeft: 5,
                flexDirection: 'row-reverse',
                width: '100% !important',
                height: this.props.height,
            }),
            clearIndicator: base => ({
                ...base,
                position: 'absolute',
                right: 20,
                padding:0,
            }),
            valueContainer: base => ({
                ...base,
                paddingRight: '1.3rem',
                height: '100%',
                overflowY: 'unset',
            }),
            placeholder: base => ({
                ...base,
                color: '#4B5563',
            }),
        }

        return (
            <AsyncSelect
                id={this.props.id}
                defaultOptions
                loadOptions={promiseOptions}
                isMulti={true}
                onChange={this.handleAttorneyName}
                isSearchable={true}
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator,
                    LoadingIndicator: () => null,
                }}
                styles={styles}
                noOptionsMessage={noOptionsMessage}
                filterOption={null}
                placeholder="Area of practice, specialty..."
            />
        );
    }
}

export default SelectSearchCategory;
