import React from "react";
import { InlineWidget, useCalendlyEventListener} from "react-calendly";

const NewAppointment = ({ client, attorney, meeting, selected_value, attorneyUrl, clientUrl}) => {
    useCalendlyEventListener({
        onProfilePageViewed: () => console.log("onProfilePageViewed"),
        onDateAndTimeSelected: () =>
        {
            console.log(meeting.meetingId);

            fetch(`/verify_meeting`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    whereby_meeting: meeting
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        console.log("There is no meeting with the same Whereby ID:  ", response);
                    } else {
                        console.log("There is another meeting with same WherebyID: ", response);
                        window.location.reload(); // refresh the page, so a new Whereby Meeting ID will be generated
                    }
                })
                .catch((error) => {
                    console.log("Error when calling /verify_meeting API: ", error);
                });
        },
        onEventTypeViewed: () => console.log("onEventTypeViewed"),
        onEventScheduled: (e) =>
        {
            console.log(e.data.payload)

            fetch(`/appointments`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    attorney_id: attorney.id,
                    event_uri: e.data.payload.event.uri,
                    invitee_uri: e.data.payload.invitee.uri,
                    whereby_meeting: meeting,
                    selected_value: selected_value
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        console.log("Successfully created the appointment");
                    } else {
                        console.log("Error creating appointment: ", response);
                    }
                })
                .catch((error) => {
                    console.log("Error creating appointment: ", error);
                });
        },
    });

    return (
        <div className="App">
            <InlineWidget url= {`${attorney.calendly_url}?hide_gdpr_banner=1`}
              prefill={{
                  name: `${client.firstName} ${client.lastName}`,
                  email: `${client.email}`,
                  location: `Whereby link`,
                  customAnswers: {
                      a1: 'Client meeting link: ' + clientUrl
                            + '\n\n' + 'Attorney meeting link: ' + attorneyUrl
                  }
              }}
            />
        </div>
    );
};

export default NewAppointment;