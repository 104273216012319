import React, {useState, useRef, useEffect} from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";

const Input = (props) => <components.Input {...props} isHidden={false} />;

export default function EditableSelect(params) {
    // This needs to become a controlled component so track state
    const [value, setValue] = useState();
    const [inputValue, setInputValue] = useState("");
    const [defaultOptions, setDefaultOptions] = useState([]);

    const selectRef = useRef();

    useEffect(() => {
        loadDefaultOptions(inputValue);
    }, []);

    const onInputChange = (localInputValue, { action }) => {

        if (action === "input-change") {
            setInputValue(localInputValue);
            updateAttorneyListFromHomepageByName(localInputValue);
            if (localInputValue.length === 0) {
                loadDefaultOptions(localInputValue);
            }
        }

        if (action === "menu-close") {
            loadDefaultOptions(inputValue);
        }
    };

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                         stroke="gray" className="w-7 h-7">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                    </svg>

                </components.DropdownIndicator>
            )
        );
    };

    const loadDefaultOptions = localInputValue => {
        promiseOptions(localInputValue).then(defaultOptions => {
                setDefaultOptions(defaultOptions)
            }
        );
    };

    const promiseOptions = localInputValue => {
        const url = `${params.apiUrl}${localInputValue ? '?q=' + localInputValue : ''}`;

        return fetch(url)
            .then(res => res.json())
            .then(json => json.attorneys)
            .catch(err => {
                console.error(err);
            });
    };

    const noOptionsMessage = (obj) => {
        if (obj.inputValue.trim().length === 0) {
            return null
        }
        return 'No matching attorneys found'
    }

    const styles = {
        control: (base, state) => ({
            ...base,
            boxShadow: 0,
            paddingLeft: 5,
            flexDirection: 'row-reverse',
            width: '100% !important',
            height: params.height,
        }),
        clearIndicator: base => ({
            ...base,
            position: 'absolute',
            right: 0,
        }),
        valueContainer: base => ({
            ...base,
            paddingRight: '2.3rem',
        }),
        placeholder: base => ({
            ...base,
            color: '#4B5563',
        }),
    }

    const onChange = (option, { action }) => {

        setValue(option);
        setInputValue(option ? option.label : "");
        loadDefaultOptions(option ? option.label : "");
        updateAttorneyListFromHomepageByName(option ? option.label : "");
    };

    return (
        <div style={{ fontFamily: "sans-serif" }}>
            <AsyncSelect
                id={params.id}
                ref={selectRef}
                defaultOptions={defaultOptions}
                loadOptions={promiseOptions}
                isClearable={true}
                value={value}
                inputValue={inputValue}
                onInputChange={onInputChange}
                noOptionsMessage={noOptionsMessage}
                onChange={onChange}
                styles={styles}
                placeholder="Attorney, Office, Firm"
                controlShouldRenderValue={false}
                components={{
                    Input,
                    IndicatorSeparator: () => null,
                    DropdownIndicator,
                    LoadingIndicator: () => null,
                }}
            />
        </div>
    );
}
